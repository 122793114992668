import React, { useState } from 'react';
import Layout from '../components/layout';

import TransitionLink from 'gatsby-plugin-transition-link';
import Link from 'gatsby-plugin-transition-link';

import LeftChevron from '../images/left-chevron.svg';
import CloseBtn from '../images/close-icon.svg';

import { Reveal, FadeIn, FadeInTop, Timeline } from '../components/animation';

const AccordionSection = ({ label, children, onClick, isOpen, index }) => (
  <>
    <div className="collapsible">
      <Reveal>
        <FadeInTop position="<=0.5">
          <div
            className={`link-wrapper `}
            onClick={() => onClick(index)}
            role="link"
            tabIndex={0}
          >
            <img
              className={`chevron ${isOpen ? 'chevron-open' : ''}`}
              alt="chevron"
              src={LeftChevron}
            />
            <span class="label-wrap">
              <h3 class={`label ${isOpen ? 'selected' : ''}`}>{label}</h3>
              <i className="hover-line"></i>
            </span>
          </div>
        </FadeInTop>
      </Reveal>
      <div className={`accordion-content ${isOpen ? 'section-visible' : ''}`}>
        <Timeline playState={isOpen ? 'play' : 'reverse'}>
          <FadeInTop stagger={0.5}>{children}</FadeInTop>
        </Timeline>
      </div>
    </div>
  </>
);

const Accordion = ({ children }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleClick = (index) => {
    if (index === openIndex) {
      setOpenIndex(null);
    } else setOpenIndex(index);
  };

  return (
    <div className="accordion">
      {children.map((child) => (
        <AccordionSection
          label={child.props.label}
          onClick={handleClick}
          isOpen={openIndex === child.props.index ? true : false}
          index={child.props.index}
        >
          {child.props.children}
        </AccordionSection>
      ))}
    </div>
  );
};

const AboutPage = () => (
  <Layout pageTitle="About">
    <div className="about-wrapper">
      <div className="container">
        <div className="container--inner about-content">
          <Link to="/">
            <p className="close-btn">
              close
              <img src={CloseBtn} />
            </p>
          </Link>
          <Reveal>
            <FadeInTop>
              <h1>about us</h1>
            </FadeInTop>
          </Reveal>
          <Reveal>
            <FadeIn>
              <p className="main-p p-text">
                Credup was established to create a more flexible loan
                environment in Nigeria, providing client-focused services with a
                wider array of options for securing loans.
              </p>
            </FadeIn>
          </Reveal>
          <Reveal>
            <FadeIn>
              <p className="p-text">
                Credup goes beyond the scope of conventional or traditional
                loan-financing to allow for better and more convenient access to
                credit using valued assets owned by people or businesses.
              </p>
            </FadeIn>
          </Reveal>
          <Reveal>
            <FadeIn>
              <p className="p-text">
                We provide swift short-term loans to individual clients and
                businesses, and payday loans to salaried loan applicants.
              </p>
            </FadeIn>
          </Reveal>

          <Reveal>
            <FadeIn>
              <p className="p-text">
                We collaborate with you to work out the loan option best suited
                for you with flexible repayment plans and the assurance of
                regaining your valued asset once repayment is complete.
              </p>
            </FadeIn>
          </Reveal>
          <Reveal>
            <FadeIn>
              <h2>Vision and Mission statements</h2>
            </FadeIn>
          </Reveal>
          <Reveal>
            <FadeIn stagger={0.5}>
              <h4 className="p-text heading-4">Vision</h4>
              <p className="p-text">
                Nigeria’s foremost credit institution providing fast and
                convenient financial solutions for individuals and businesses.
              </p>
            </FadeIn>
          </Reveal>
          <Reveal>
            <FadeIn>
              <h4 className="p-text heading-4">Mission</h4>
              <p className="p-text">
                Harnessing technology to bring credit options that relieve the
                economic burden posed to individuals and businesses in the
                country.
              </p>
              <p className="p-text">
                To provide easily accessible and flexible loan options to every
                potential applicant.
              </p>
            </FadeIn>
          </Reveal>
        </div>
        <div className="about-faq-loans" id="faq-loans">
          <Accordion>
            <div label="FAQs" index={0}>
              <div className="faq-wrap">
                <div className="faq-group">
                  <div className="faq-item">
                    <h3>How to apply?</h3>
                    <ul>
                      <li>
                        Make your loan request stating your preferred amount and
                        tenor by contacting us on{' '}
                        <a href="mailto:info@credup.ng">info@credup.ng</a>
                      </li>
                      <li>Execute and submit all required documents.</li>
                      <li>Credit disbursement is made.</li>
                    </ul>
                  </div>
                  <div className="faq-item">
                    <h3>
                      What is the minimum and maximum amount that can be
                      borrowed?
                    </h3>
                    <p>
                      For payday loans, minimum amount is N100,000 while maximum
                      is N2,000,000
                    </p>
                    <p>
                      {' '}
                      For business loans, minimum amount is N500,000 while max
                      is N10,000,000
                    </p>
                  </div>
                </div>

                <div className="faq-group">
                  <div className="faq-item">
                    <h3>What valued assets can I pawn?</h3>
                    <p>
                      Valued assets we're interested in include - motor
                      vehicles, articulated trucks, jewellery items, high-value
                      electronic sets, power generating sets, stocks etc.
                    </p>
                    <p>Land or buildings are not suitable/accepted by us.</p>
                  </div>
                  <div className="faq-item">
                    <h3>Do I get my Pawned Asset back after full repayment?</h3>
                    <p>
                      Certainly. Once the loan facility is liquidated, inclusive
                      of all interest sum, the pawned assets are given back to
                      the client
                    </p>
                  </div>
                </div>

                <div className="faq-group">
                  <div className="faq-item">
                    <h3>What are your types of loan?</h3>
                    <p>Payday loans and business loans</p>
                  </div>
                </div>
              </div>
            </div>
            <div label="Types of loans" index={1}>
              <div className="faq-wrap types-of-loans">
                <div className="faq-item">
                  <h3>Payday loans</h3>
                  <p>
                    This loan is accessible to anyone on a salary at a reputable
                    organization.
                  </p>
                  <p>
                    You make repayments on your ‘payday’, this is to help sort
                    out emergencies or financial burdens that may come up before
                    the next salary comes in or for amounts larger than your
                    salary.
                  </p>
                  <p>Whatever the need is, you can ‘CredUp’.</p>
                </div>
                <div className="faq-item">
                  <h3>Business loans</h3>
                  <p>
                    The primary criterion for accessing CredUp’s business loan
                    is to be a business!
                  </p>
                  <p>
                    Using your company assets, you can get a loan to the sum of
                    N5,000,000 for your organization.
                  </p>
                  <p>
                    Loans can be used to pay for supplies, expansion, equipment
                    purchase and bridge working capital requirements.
                  </p>
                </div>
              </div>
            </div>
          </Accordion>
        </div>
      </div>
    </div>
  </Layout>
);

export default AboutPage;
